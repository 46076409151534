<template>
  <v-container>
    <v-card class="pa-5">
      <v-card-title>
        <v-row>
          <v-col class="text-h4 text-left">{{ terminalName }}</v-col>
          <v-col class="text-right">
            <v-btn append-icon="mdi-cog" variant="tonal">
              {{ t("settings") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="mb-5 text-left">{{
        t("bookingOverview")
      }}</v-card-subtitle>
      <v-data-table
        :sort-by="sortBy"
        :headers="headers"
        :items="sortedBookings"
        item-key="referenceNumber"
        items-per-page="10"
        :items-per-page-text="t('items_per_page')"
        :page-text="`{0}-{1} ${t('of')} {2}`"
        :no-data-text="t('no_data_available')"
      >
        <template #item="{ item }">
          <tr>
            <td>
              <span
                :class="[
                  'led-indicator',
                  getLedColor(
                    item.state ?? TerminalOperatorBookingTOStateEnum.Active,
                  ),
                ]"
              ></span>
            </td>
            <td>{{ t(item.type ?? "") }}</td>
            <td>{{ item.referenceNumber }}</td>
            <td>{{ item.containerNumber }}</td>
            <td>{{ formatDate(item.timeSlot?.slotBegin) }}</td>
            <td>
              {{ formatTime(item.timeSlot?.slotBegin) }} -
              {{ formatTime(item.timeSlot?.slotEnd) }}
            </td>
            <td>{{ t(item.state ?? t("statusNotFound")) }}</td>
            <td class="text-right">
              <v-btn
                color="primary"
                :disabled="
                  item.state === TerminalOperatorBookingTOStateEnum.Completed
                "
                @click="rerouteToParking(item)"
              >
                {{ t("rerouteToParking") }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import { TerminalOperatorBookingTOStateEnum } from "@/services/client/generated";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTerminalBookingOverviewLogic } from "./terminal-booking-overview-logic";

const { t } = useI18n();
const {
  sortedBookings,
  formatTime,
  formatDate,
  getLedColor,
  sortDateRaw,
  rerouteToParking,
  sortStatusRaw,
  terminalName,
} = useTerminalBookingOverviewLogic();

const sortBy = ref<
  {
    key: string;
    order: "asc" | "desc";
  }[]
>([
  { key: "state", order: "asc" },
  { key: "date", order: "desc" },
]);

const headers = computed(
  () =>
    [
      { title: "", key: "led", width: "1em", sortable: false },
      { title: t("type"), key: "type", align: "center", sortable: false },
      {
        title: t("reference_number"),
        key: "referenceNumber",
        align: "center",
        sortable: false,
      },
      {
        title: t("containerNumber"),
        key: "containerNumber",
        align: "center",
        sortable: false,
      },
      { title: t("date"), key: "date", align: "center", sortRaw: sortDateRaw },
      {
        title: t("timeSlot"),
        key: "timeSlot",
        align: "center",
        sortable: false,
      },
      {
        title: t("status"),
        key: "state",
        align: "center",
        width: "30em",
        sortable: true,
        sortRaw: sortStatusRaw,
      },
      {
        title: "",
        key: "actions",
        width: "8em",
        sortable: false,
      },
    ] as const,
);
</script>

<style scoped>
.led-indicator {
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

.led-green {
  background-color: green;
}

.led-yellow {
  background-color: yellow;
}

.led-grey {
  background-color: grey;
}
</style>
