<template>
  <v-card class="card rounded-lg" color="#00295B" elevation="2" rounded>
    <v-card-title class="card-title">
      <v-container class="pa-0 ma-0">
        <v-form v-model="valid">
          <v-col align="start" class="acceptance-title">
            {{ $t("createAcceptance").toUpperCase() }}
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  bg-color="white"
                  class="rounded ma-0 pa-0 text-input"
                  v-model="trainNumber"
                  :placeholder="$t('trainNumber')"
                  :rules="[
                    (v: string) => (!!v && v !== '0') || $t('fieldRequired'),
                    (v: string) => /^\d+$/.test(v) || $t('onlyNumbers'),
                  ]"
                  :error-messages="trainNumberError"
                  required
                />
              </v-col>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  :offset="40"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      class="rounded ma-0 pa-0 text-input"
                      bg-color="white"
                      readonly
                      :placeholder="departurePlaceholder"
                      :model-value="fakeDepartureDate"
                      v-bind="props"
                      :label="$t('departureDate')"
                      data-testid="test-departureDate"
                      :error-messages="departureDateError"
                    />
                  </template>
                  <v-date-picker
                    v-model="departureDate"
                    :title="$t('select_date')"
                    :no-data-text="$t('no_data_available')"
                    color="green"
                    no-title
                    :min="dateFormatted(today)"
                    @click="updateDepartureDate"
                  />
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  :offset="40"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      class="rounded ma-0 pa-0 text-input"
                      bg-color="white"
                      :rules="[areAcceptanceDatesCorrect]"
                      readonly
                      :placeholder="acceptanceDeadlinePlaceholder"
                      :model-value="fakeDeadlineDate"
                      v-bind="props"
                      :error-messages="acceptanceDatesError"
                    />
                  </template>
                  <v-date-picker
                    v-model="acceptanceDates"
                    :title="$t('select_date')"
                    multiple="range"
                    color="green"
                    no-title
                    :min="dateFormatted(today)"
                    :max="dateFormatted(departureDate)"
                  />
                </v-menu>
              </v-col>
              <v-col>
                <TheButton
                  color="#ff005b"
                  @click.native="addAcceptanceDeadline"
                  :loading="false"
                  :title="$t('create')"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-container>
    </v-card-title>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { format } from "date-fns";
import TheButton from "@/components/TheButton.vue";
import { getErrorByType } from "@/utils/error-utils";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./acceptance-restriction-logic";

const viewModel = getViewModel();
const i18n = useI18n();
const emit = defineEmits(["created-deadline"]);

const valid = ref(false);
const trainNumber = ref("");
const today = new Date();
const departureDate = ref(new Date(today));
const acceptanceDates = ref<Date[]>([]);
const trainNumberError = ref("");
const departureDateError = ref("");
const acceptanceDatesError = ref("");

const dateFormatted = (date: Date): string => {
  return format(date, "yyyy-MM-dd");
};

const areAcceptanceDatesCorrect = (): boolean | string => {
  return viewModel.isAcceptanceDeadlineCorrect(acceptanceDates.value);
};

const showErrorMessages = () => {
  if (!trainNumber.value)
    trainNumberError.value = i18n.t("fieldRequired") as string;
  if (!departureDate.value)
    departureDateError.value = i18n.t("fieldRequired") as string;
  if (typeof areAcceptanceDatesCorrect() === "string")
    acceptanceDatesError.value = i18n.t("pickBothDates") as string;
  setTimeout(() => {
    trainNumberError.value = "";
    departureDateError.value = "";
    acceptanceDatesError.value = "";
  }, 3000);
};

const updateList = () => {
  emit("created-deadline");
};

const updateDepartureDate = () => {
  acceptanceDates.value = [];
};

const addAcceptanceDeadline = async () => {
  try {
    if (!valid.value) {
      showErrorMessages();
      return;
    }

    await viewModel.addAcceptanceDeadline(
      acceptanceDates.value,
      dateFormatted(departureDate.value),
      trainNumber.value,
    );
    updateList();
  } catch (e) {
    const errorMessage = getErrorByType(e);

    await viewModel.showSnackbarError(errorMessage);
  }
};

const departurePlaceholder = computed(() => i18n.t("departureDate") as string);
const acceptanceDeadlinePlaceholder = computed(
  () => `${i18n.t("startDate")} - ${i18n.t("endDate")}`,
);
const fakeDepartureDate = computed(() =>
  viewModel.getFakeDate(departureDate.value),
);
const fakeDeadlineDate = computed(() =>
  viewModel.getFakeDeadlineDates(acceptanceDates.value),
);
</script>

<style lang="scss" scoped>
:deep(.text-input) {
  :is(input) {
    padding-left: 15px;
    height: 40px;
  }
}

.acceptance-title {
  color: white;
  font-weight: bold;
  font-size: 1.6em;
}

.card-title {
  color: white;
  font-weight: bold;
  font-size: 1em;
}
</style>
