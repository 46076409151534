<template>
  <v-select
    class="select"
    density="default"
    :items="trainOperatorsNames"
    :label="$t('selectTrainOperator')"
    :no-data-text="$t('no_data_available')"
    v-model="trainOperatorCurrentName"
    hide-details
    @update:modelValue="handleModelValueUpdate"
    variant="outlined"
    :return-object="true"
  />
</template>

<script setup lang="ts">
import { ref, computed, PropType } from "vue";
import { TrainOperator } from "@/services/client/generated";
import DropdownItem from "@/internal-models/dropdown-item";

const props = defineProps({
  trainOperators: {
    type: Array as PropType<TrainOperator[]>,
    required: true,
  },
});

const emit = defineEmits(["change-train-operator"]);

const currentlySelectedTrainOperator = ref<DropdownItem | null>(null);

const changeTrainOperator = (item: TrainOperator): void => {
  currentlySelectedTrainOperator.value = { title: item.name, value: item };
  emit("change-train-operator", item);
};

const handleModelValueUpdate = (item: DropdownItem): void => {
  const trainOperator = item.value as TrainOperator;
  changeTrainOperator(trainOperator);
};

const trainOperatorsNames = computed<DropdownItem[]>(() => {
  return props.trainOperators.map(e => ({
    title: e.name,
    value: e,
  }));
});

const trainOperatorCurrentName = computed<DropdownItem>(() => {
  if (currentlySelectedTrainOperator.value) {
    return currentlySelectedTrainOperator.value;
  }
  return {
    title: props.trainOperators[0].name,
    value: props.trainOperators[0],
  };
});
</script>

<style scoped lang="scss">
.select {
  width: 100%;
}
</style>
