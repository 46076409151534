<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="$t('dashboard')" />

    <div>
      <v-container fluid class="pt-10 pr-8">
        <v-row>
          <v-col sm="4" md="2" xl="2" offset-md="6" offset-xl="8">
            <TimeRangeDropdown
              @change-range="changeRange"
              :custom-range="customRange"
            />
          </v-col>
          <v-col sm="8" md="4" xl="2">
            <v-form v-model="valid">
              <v-row>
                <v-col cols="6">
                  <v-menu
                    :close-on-content-click="false"
                    :offset="40"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        class="rounded ma-0 pa-0"
                        bg-color="white"
                        readonly
                        variant="outlined"
                        density="default"
                        :rules="[dateNotEmpty]"
                        :placeholder="startDatePlaceHolder"
                        :model-value="formatDateInput(startDate)"
                        v-bind="props"
                        :label="$t('startDate')"
                        data-testid="test-startDate"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      :title="$t('select_date')"
                      color="green"
                      :no-data-text="$t('no_data_available')"
                      no-title
                      @update:modelValue="startDateChange"
                      :allowed-dates="allowedDates"
                      :min="minDate"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    :close-on-content-click="false"
                    :offset="40"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        class="rounded ma-0 pa-0 heightTall"
                        bg-color="white"
                        readonly
                        variant="outlined"
                        density="default"
                        :rules="[dateNotEmpty]"
                        :placeholder="endDatePlaceHolder"
                        :model-value="formatDateInput(endDate)"
                        v-bind="props"
                        :label="$t('endDate')"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :title="$t('select_date')"
                      color="green"
                      no-title
                      @update:modelValue="endDateChange"
                      :allowed-dates="allowedDates"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" sm="12" md="7" lg="8">
            <v-row>
              <v-col sm="12">
                <TimeSeriesChart
                  v-if="chartData"
                  :data="chartData"
                  :loading="chartLoading"
                  :title="$t('completedBookings')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="6" md="6">
                <ThePageCard :usable="true" :item="pageCards.blocklist" />
              </v-col>
              <v-col xs="12" sm="6" md="6">
                <ThePageCard :usable="true" :item="pageCards.deadlines" />
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="6" md="6">
                <ThePageCard
                  :usable="false"
                  :item="pageCards.modulemanagment"
                />
              </v-col>
              <v-col xs="12" sm="6" md="6">
                <ThePageCard :usable="true" :item="pageCards.bookingOverview" />
              </v-col>
            </v-row>
          </v-col>
          <v-col xs="12" sm="12" md="5" lg="4">
            <v-row>
              <v-col xs="12" sm="6" md="6">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[0]]"
                />
              </v-col>
              <v-col xs="12" sm="6" md="6">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[1]]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="6" md="6">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[2]]"
                />
              </v-col>
              <v-col xs="12" sm="6" md="6">
                <TheStatisticValueBox
                  :statistic-value="statisticValues[statisticNames[3]]"
                  font-size="small"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
// Components
import TheMainHeader from "@/components/TheMainHeader.vue";
import TimeSeriesChart from "@/components/TimeSeriesChart.vue";
import { TimeSeriesChartData } from "@/internal-models/charts";
import TheStatisticValueBox from "@/components/TheStatisticValueBox.vue";
import ThePageCard from "@/components/ThePageCard.vue";
import TimeRangeDropdown from "@/views/terminal-operator/dashboard/TimeRangeDropdown.vue";

// Constants
import getTerminalOperatorPageCards from "@/constants/terminal-operator-page-cards";
import TimeRange from "@/internal-models/time-range";
import Duration from "@/internal-models/enums/duration";
import {
  RangedTerminalStatisticsValueRequest,
  RangedTerminalStatisticsValueResponse,
  TimeSeriesResponse,
} from "@/services/client/generated";
import StatisticValue from "@/internal-models/statistic-value";
import {
  findBorderColor,
  getStatisticTypeFromStringForTerminal,
} from "@/utils/statistic-utils";

// Utilities
import {
  format,
  compareAsc,
  parseISO,
  sub,
  startOfDay,
  endOfDay,
  formatISO,
} from "date-fns";

// Plugins
import i18n from "@/plugins/i18n";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./terminal-dashboard-logic";

const viewModel = getViewModel();
const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();

// Reactive state
const pageCards = getTerminalOperatorPageCards();
const valid = ref(false);
const range = ref<TimeRange>({ duration: Duration.DAYS, amount: 1 });
const statisticValues = ref<Record<string, StatisticValue>>({});
const statisticNames = [
  "active_bookings",
  "completed_bookings",
  "canceled_bookings",
  "time_register_success_transhipment",
];
let intervalId: number | NodeJS.Timeout = -1;
const updateInterval = 1000 * 60;
const today = new Date();
const startDate = ref(new Date(today));
const endDate = ref(new Date(today));
const customRange = ref(false);
const chartData = ref<TimeSeriesChartData | null>({ xAxis: [], yAxis: [] });
const chartLoading = ref(true);

// Lifecycle hooks
onMounted(async () => {
  intervalId = setInterval(
    async () => await updateStatisticValues(),
    updateInterval,
  );
  await updateStatisticValues();
  // valid.value;
});

onBeforeUnmount(() => {
  if (intervalId !== -1) {
    clearInterval(intervalId);
  }
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await updateStatisticValues();
    }
  },
);

// Methods
const formatDateInput = (date: Date) => format(date, "dd.MM.yyyy");

const allowedDates = (date: unknown) => {
  const today = new Date();
  if (date instanceof Date) {
    return compareAsc(today, date) > 0; // if date is in the past
  }
  return false;
};

const startDateChange = (): void => {
  customRange.value = true;
  if (!validate()) return;
  if (compareAsc(startDate.value, endDate.value) > 0) {
    endDate.value = new Date(startDate.value);
  }
  updateStatisticValues();
};

const endDateChange = (): void => {
  customRange.value = true;
  if (!validate()) return;
  if (compareAsc(startDate.value, endDate.value) > 0) {
    startDate.value = new Date(endDate.value);
  }
  updateStatisticValues();
};

const dateNotEmpty = (date: Date) =>
  (!!date && date != null) || i18n.global.t("fieldRequired");

const validate = (): boolean => valid.value;

const updateStatisticValues = async (): Promise<void> => {
  const responses = await Promise.all(
    customRange.value
      ? statisticNames.map(e =>
          viewModel.fetchDataForStatisticBox(
            pickCustomFloatStatisticRequest(e),
          ),
        )
      : statisticNames.map(e =>
          viewModel.fetchDataForStatisticBox(
            pickFloatStatisticRequest(range.value, e),
          ),
        ),
  );

  const result: Record<string, StatisticValue> = {};
  responses.forEach(statistic => {
    const parsedResponse = parseFloatResponse(statistic);
    if (parsedResponse) {
      result[statistic.name] = parsedResponse;
    }
  });
  statisticValues.value = result;

  chartLoading.value = true;
  await fetchData();
  chartLoading.value = false;
};

const fetchData = async (): Promise<void> => {
  let response: TimeSeriesResponse;
  if (startDate.value && endDate.value) {
    response = await viewModel.fetchTimeSeriesChartDataForCustomRange(
      startDate.value.toDateString(),
      endDate.value.toDateString(),
    );
  } else {
    response = await viewModel.fetchTimeSeriesChartData(range.value);
  }
  chartData.value = { xAxis: response.xAxis, yAxis: response.yAxis };
};

const pickFloatStatisticRequest = (
  range: TimeRange,
  type: string,
): RangedTerminalStatisticsValueRequest => {
  const floatStatistic = viewModel.pickFloatStatistic(
    range.duration,
    range.amount,
    type,
  );
  startDate.value = parseISO(floatStatistic.start);
  endDate.value = parseISO(floatStatistic.end);
  return floatStatistic;
};

const pickCustomFloatStatisticRequest = (
  type: string,
): RangedTerminalStatisticsValueRequest => ({
  start: startOfDayIso(startDate.value),
  end: endOfDayIso(endDate.value),
  name: getStatisticTypeFromStringForTerminal(type),
});

const parseFloatResponse = (
  response: RangedTerminalStatisticsValueResponse,
): StatisticValue => {
  return {
    color: findBorderColor(response),
    title: response.name ?? "",
    unit: response.unit ?? "",
    value: response.floatValue ?? 0,
  };
};

const changeRange = async (newRange: TimeRange): Promise<void> => {
  customRange.value = false;
  range.value = newRange;
  endDate.value = new Date();
  startDate.value = new Date();
  await updateStatisticValues();
};

const startOfDayIso = (date: Date): string => {
  const startDate = startOfDay(date);
  return startDate.toISOString();
};

const endOfDayIso = (date: Date): string => {
  const endDate = endOfDay(date);
  return endDate.toISOString();
};

// Computed properties
const minDate = computed(() => {
  const twoYearsBeforeEndDate = sub(endDate.value, { years: 2 });
  return formatISO(twoYearsBeforeEndDate);
});

const startDatePlaceHolder = computed(
  () => i18n.global.t("startDate") as string,
);

const endDatePlaceHolder = computed(() => i18n.global.t("endDate") as string);
</script>

<style scoped>
:deep(.text-input) {
  :is(input) {
    height: 40px;
  }
}
</style>
