import { ref, onMounted, onUnmounted, computed } from "vue";

type FetchItemsCallback = (page: number, pageSize: number) => Promise<boolean>;

export const usePagination = (callback: FetchItemsCallback) => {
  const PAGE_SIZE = 20;
  const DEBOUNCE_DELAY = 200;
  const pageIndex = ref(0);
  const loading = ref(false);
  let fetchedAllItems = false;

  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  // Debounce function to prevent rapid consecutive fetches
  const debounce = (func: () => void, delay: number) => {
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(func, delay);
  };

  const listenToScroll = () => {
    // Throttle the scroll event to avoid excessive calls
    debounce(async () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !fetchedAllItems &&
        !loading.value
      ) {
        loading.value = true;

        try {
          const fetchedAll = await callback(pageIndex.value, PAGE_SIZE);
          if (fetchedAll) {
            fetchedAllItems = true;
          } else {
            pageIndex.value++;
          }
        } catch (error) {
          console.error("Error fetching items:", error);
        } finally {
          loading.value = false;
        }
      }
    }, DEBOUNCE_DELAY);
  };

  onMounted(() => {
    window.addEventListener("scroll", listenToScroll);
  });

  onUnmounted(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    window.removeEventListener("scroll", listenToScroll);
  });

  return {
    loading: computed(() => loading.value),
    pageIndex: computed(() => pageIndex.value),
    pageSize: PAGE_SIZE,
    restartPageIndex: () => {
      pageIndex.value = 0;
      fetchedAllItems = false;
    },
    incrementPageIndex: () => {
      pageIndex.value++;
    },
  };
};
