<template>
  <v-container fluid class="pr-8">
    <v-card class="pa-5">
      <v-card-title>
        <v-row>
          <v-col class="text-h4 text-left">{{ t("blockList.title") }}</v-col>
          <v-col cols="12" align="end">
            <v-btn color="primary" @click="addDialog = !addDialog">
              {{ t("blockList.add_trucker") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :items="truckers"
        :headers="headers"
        item-key="id"
        :items-per-page-text="t('items_per_page')"
        :page-text="`{0}-{1} ${t('of')} {2}`"
        :no-data-text="t('no_data_available')"
      >
        <template #item.name="{ item }">
          <span>{{ item.firstName }} {{ item.lastName }}</span>
        </template>
        <template #item.blockedDate="{ item }">
          <span>{{ formatDate(item.blockedDate ?? "") }}</span>
        </template>
        <template #item.actions="{ item }">
          <v-tooltip :text="$t('blockList.tooltip')" location="start">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                icon="mdi-trash-can-outline"
                variant="text"
                color="red"
                @click="openDeleteDialog(item)"
              />
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <DeleteBlockListEntry
      v-if="selectedTrucker"
      v-model="deleteDialog"
      :selected-trucker="selectedTrucker"
      @delete-trucker="deleteTrucker"
      @cancel-delete="cancelDelete"
    />
    <AddTruckerToBlockList
      v-model="addDialog"
      :truckers="allTruckers"
      @block-trucker="addBlockListEntry($event)"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTerminalOperatorBlockListLogic } from "./terminal-operator-block-list-logic";
import DeleteBlockListEntry from "./DeleteBlockListEntry.vue";
import { TerminalOperatorTruckerTO } from "@/services/client/generated";
import AddTruckerToBlockList from "./AddTruckerToBlockList.vue";
import { formatDate } from "@/utils/date-utils";

const { t } = useI18n();

const {
  truckers,
  addBlockListEntry,
  deleteBlockListEntry,
  getBlockListForFacility,
} = useTerminalOperatorBlockListLogic();

const deleteDialog = ref(false);
const addDialog = ref(false);
const allTruckers = ref<TerminalOperatorTruckerTO[]>([]);
const selectedTrucker = ref<TerminalOperatorTruckerTO | null>(null);

const openDeleteDialog = (item: TerminalOperatorTruckerTO) => {
  selectedTrucker.value = item;
  deleteDialog.value = true;
};

const deleteTrucker = async () => {
  if (selectedTrucker.value && selectedTrucker.value.id) {
    await deleteBlockListEntry(selectedTrucker.value.id);
    deleteDialog.value = false;
    getBlockListForFacility();
  }
};

const cancelDelete = () => {
  selectedTrucker.value = null;
  deleteDialog.value = false;
};

const headers = computed(
  () =>
    [
      { title: t("name"), value: "name", align: "center" },
      { title: t("id"), value: "id", align: "center" },
      {
        title: t("blockList.date_blocked"),
        value: "blockedDate",
        align: "center",
      },
      { title: t("blockList.reason"), value: "blockedReason", align: "center" },
      { title: "", value: "actions", align: "center" },
    ] as const,
);

onMounted(async () => {
  getBlockListForFacility();
});
</script>
