<template>
  <div v-if="items" id="select">
    <v-select
      density="compact"
      v-model="defaultValueModel"
      clearable
      @update:modelValue="changeItem"
      :items="items"
      :no-data-text="$t('no_data_available')"
      :variant="outlined ? 'outlined' : 'plain'"
      :item-title="itemTitle"
      :item-value="itemValue"
      theme="dark"
      :rules="rules"
      color="white"
      :label="$t(label)"
    >
    </v-select>
  </div>
</template>

<script setup lang="ts" generic="T">
import { watch, ref, Ref } from "vue";

type ValidatorCallback<T> = (value: T) => boolean | string;

const emits = defineEmits(["change-item"]);

const props = defineProps<{
  defaultValue?: T | null;
  label: string;
  items: T[];
  itemTitle?: string;
  itemValue?: string;
  rules?: ValidatorCallback<string>[];
  outlined?: boolean;
  translate?: boolean;
}>();

const defaultValueModel = ref<T | null>(
  props.defaultValue ?? null,
) as Ref<T | null>;

watch(
  () => props.defaultValue,
  newVal => {
    if (newVal !== undefined) {
      defaultValueModel.value = newVal;
    }
  },
);

const changeItem = (value: T | null): void => {
  emits("change-item", { label: props.label, value });
};
</script>
