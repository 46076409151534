import {
  ispsTerminalOperatorBlockList,
  webappTruckersClient,
} from "@/services/client/configs/services";
import {
  ListTruckersForFacilityBlockedStateEnum,
  TerminalOperatorTruckerTO,
  TruckerBlocklistRequestTO,
} from "@/services/client/generated";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { ref, watch } from "vue";

export function useTerminalOperatorBlockListLogic() {
  const authStore = useAuthStore();
  const terminalStore = useTerminalStore();
  const forwarderStore = useForwarderStore();

  const truckers = ref<TerminalOperatorTruckerTO[]>([]);
  const selectedTrucker = ref<TerminalOperatorTruckerTO | null>(null);
  const reason = ref("");

  async function getBlockListForFacility() {
    if (authStore.authUser && terminalStore.terminal) {
      const { data } = await webappTruckersClient.listTruckersForFacility(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        undefined,
        undefined,
        undefined,
        ListTruckersForFacilityBlockedStateEnum.Blocked,
      );
      truckers.value = data;
    } else {
      truckers.value = [];
    }
  }

  async function fetchTruckersByLastName(lastName: string) {
    if (authStore.authUser && terminalStore.terminal) {
      const { data } = await webappTruckersClient.listTruckersForFacility(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        undefined,
        undefined,
        lastName,
        ListTruckersForFacilityBlockedStateEnum.NotBlocked,
      );
      truckers.value = data;
    } else {
      truckers.value = [];
    }
  }

  async function deleteBlockListEntry(id: string) {
    if (authStore.authUser && terminalStore.terminal) {
      await ispsTerminalOperatorBlockList.deleteBlockListEntryForIspsTerminalByTrucker(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        id,
      );
      await getBlockListForFacility();
    }
  }

  async function addBlockListEntry(truckerRequest: TruckerBlocklistRequestTO) {
    if (authStore.authUser && terminalStore.terminal) {
      await ispsTerminalOperatorBlockList.createBlockListEntryForIspsTerminal(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        truckerRequest,
      );
      await getBlockListForFacility();
    }
  }

  function resetForm() {
    selectedTrucker.value = null;
    truckers.value = [];
    reason.value = "";
  }

  watch(
    () => [terminalStore.terminal, forwarderStore.forwarder],
    async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
      if (
        newTerminal?.id !== oldTerminal?.id ||
        newForwarder?.id !== oldForwarder?.id
      ) {
        await getBlockListForFacility();
      }
    },
  );

  return {
    truckers,
    selectedTrucker,
    reason,
    resetForm,
    fetchTruckersByLastName,
    getBlockListForFacility,
    deleteBlockListEntry,
    addBlockListEntry,
  };
}
