<template>
  <v-card class="card rounded-lg" color="#00295B" elevation="2" rounded>
    <v-card-title
      class="card-title ml-3"
      data-testid="createOnetoOneRestriction-text"
    >
      <v-col align="start" class="restriction-title">
        {{ $t("create_1_1_acceptance").toUpperCase() }}
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form v-model="valid">
          <v-row justify="center">
            <v-col cols="12" sm="3">
              <v-menu
                :close-on-content-click="false"
                :offset="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ props }">
                  <v-text-field
                    class="rounded ma-0 pa-0"
                    bg-color="white"
                    readonly
                    :rules="[isRestrictionCorrect]"
                    :placeholder="restrictionDatesPlaceHolder"
                    :model-value="fakeDeadlineDate"
                    v-bind="props"
                    data-testid="restriction-inputSlot"
                  />
                </template>
                <v-date-picker
                  v-model="restrictionDate"
                  :title="$t('select_date')"
                  :no-data-text="$t('no_data_available')"
                  color="green"
                  no-title
                  data-testid="restriction-datePicker"
                  :min="restrictionDateFormatted"
                />
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3">
              <HourDropdownPicker
                :label="$t('startingHourOfRestriction')"
                :hours="generateBaseHours()"
                @change-hour="putFirstHour"
                data-testid="restriction-startHourPicker"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <HourDropdownPicker
                v-show="endEnabled"
                :label="$t('endingHourOfRestriction')"
                :hours="validatedLastHours"
                @change-hour="putLastHour"
                data-testid="restriction-endHourPicker"
              />
            </v-col>
            <v-col></v-col>
            <v-col cols="12" sm="2">
              <TheButton
                color="#ff005b"
                @click.native="addOneToOneRestriction"
                :loading="false"
                :title="$t('create')"
                data-testid="create-restriction-button"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { format } from "date-fns";
import TheButton from "@/components/TheButton.vue";
import HourDropdownPicker from "@/components/HourDropdownPicker.vue";
import { useI18n } from "vue-i18n";
import { getViewModel } from "../acceptance-deadline/acceptance-restriction-logic";

const viewModel = getViewModel();
const i18n = useI18n();

const emit = defineEmits(["added-restriction"]);

const valid = ref(false);
const baseHours = ref<number[]>([]);
const validatedLastHours = ref<number[]>([]);
const startHour = ref<number | undefined>(undefined);
const endHour = ref<number | undefined>(undefined);
const today = new Date();
const restrictionDate = ref(new Date(today));
const endEnabled = ref(false);

const addOneToOneRestriction = () => {
  if (!valid.value) return;
  emit("added-restriction", {
    restrictionDate: restrictionDate.value,
    startHour: startHour.value,
    endHour: endHour.value,
  });
};

const isRestrictionCorrect = (): string | boolean => {
  return viewModel.isOneToOneRestrictionCorrect(restrictionDate.value);
};

const generateBaseHours = (): number[] => {
  let currentHour = today.getHours();
  if (restrictionDate.value.getDate() != today.getDate()) {
    currentHour = 0;
  }
  const hoursArray = new Array(24 - currentHour)
    .fill(0)
    .map((_, i) => i + currentHour);
  return hoursArray;
};

const putFirstHour = (value: string) => {
  const selectedHour = parseInt(value.split(":")[0], 10);
  startHour.value = selectedHour;
  validatedLastHours.value = baseHours.value.filter(e => e > selectedHour);
  endEnabled.value = true;
};

const putLastHour = (value: string) => {
  const selectedHour = parseInt(value.split(":")[0], 10);
  endHour.value = selectedHour;
};

const fakeDeadlineDate = computed(() =>
  viewModel.getFakeDate(restrictionDate.value),
);

const restrictionDatesPlaceHolder = computed(
  () => i18n.t("oneToOneDate") as string,
);

const restrictionDateFormatted = computed(() => format(today, "yyyy-MM-dd"));

onMounted(() => {
  baseHours.value = generateBaseHours();
});
</script>

<style scoped lang="scss">
:deep(.text-input) {
  :is(input) {
    padding-left: 15px;
    font-weight: bold;
    height: 40px;
  }
}

.card-title {
  color: white;
  font-weight: bold;
  font-size: 1em;
}

.restriction-title {
  color: white;
  font-weight: bold;
}

.restriction-title {
  @media only screen and (min-width: 400px) {
    font-size: 1rem;
  }

  @media only screen and (min-width: 600px) {
    font-size: 1.1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1.2rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.6rem;
  }
}
</style>
