<template>
  <div>
    <v-navigation-drawer :width="65" permanent>
      <div @click="router.push('/')" class="cursor-pointer">
        <v-img :src="ConrooLogo" :height="46" cover />
      </div>

      <v-divider class="mx-2" />

      <v-list variant="flat" class="pt-10" nav :items="groupedItems">
        <ListItem
          v-for="(item, i) in groupedItems"
          v-show="hasPermission(item.roles)"
          @click="handleItemClick(item)"
          :item="item"
          :key="i"
        />
      </v-list>
      <template #append>
        <v-list nav>
          <LanguageSwitch />

          <v-list-item
            class="pa-0 pl-3"
            prepend-icon="mdi-help-circle-outline"
            base-color="primary"
            @click="goToSupport"
            variant="text"
          />

          <v-list-item
            @click="logOut"
            prepend-icon="mdi-logout"
            :title="$t('logout')"
            data-testid="test-logout"
            base-color="primary"
            class="pa-0 pl-3"
            variant="text"
          />
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="drawer" :width="280">
      <template v-if="selectedItem">
        <div v-if="selectedItem.pageName === PAGE_NAMES.TERMINAL_OPERATOR">
          <p class="text-primary font-weight-medium pt-2">
            {{ $t("terminalOperator") }}
          </p>
          <div>
            <TerminalDropdown
              v-if="
                hasPermission(groupedItems[0].roles) ||
                hasPermission(groupedItems[1].roles)
              "
              class=""
              data-testid="test-TerminalDropdown"
            />
          </div>
          <div>
            <p class="text-primary font-weight-medium pt-2 pb-4">Services</p>
            <v-list
              v-for="service in groupedItems[0].services"
              :key="service.name"
              class="pa-1"
            >
              <v-list-item
                active-color="primary"
                active-class="active"
                class="d-flex list-item mx-3 rounded-lg cursor-pointer"
                :to="service.pagePath"
                :replace="true"
              >
                <template #prepend>
                  <div class="mr-2">
                    <v-icon :icon="getIcon(service)"></v-icon>
                  </div>
                </template>
                <v-list-item-title
                  class="font-weight-medium text-white"
                  :class="serviceTitleClass(service.pagePath)"
                >
                  {{ t(service.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </div>
        <div v-else-if="selectedItem.pageName === PAGE_NAMES.TRAIN_OPERATOR">
          <p class="text-primary font-weight-medium pt-2">
            {{ $t("trainOperator") }}
          </p>
          <div>
            <TerminalDropdown
              v-if="
                hasPermission(groupedItems[0].roles) ||
                hasPermission(groupedItems[1].roles)
              "
              data-testid="test-TerminalDropdown"
            />
            <ForwarderDropdown
              v-if="hasPermission(groupedItems[2].roles)"
              data-testid="test-ForwarderDropdown"
            />
          </div>

          <div>
            <p class="text-primary font-weight-medium pt-2 pb-4">Services</p>
            <v-list
              v-for="service in groupedItems[1].services"
              :key="service.name"
              class="pa-1"
            >
              <v-list-item
                active-color="primary"
                active-class="active"
                class="d-flex list-item mx-3 rounded-lg cursor-pointer"
                :to="service.pagePath"
                :replace="true"
              >
                <template #prepend>
                  <div class="mr-2">
                    <v-icon :icon="getIcon(service)"></v-icon>
                  </div>
                </template>
                <v-list-item-title
                  class="font-weight-medium"
                  :class="serviceTitleClass(service.pagePath)"
                >
                  {{ t(service.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </div>
        <div
          v-else-if="selectedItem.pageName === PAGE_NAMES.FORWARDER_OPERATOR"
        >
          <p class="text-primary font-weight-medium pt-2">
            {{ $t("forwarderOperator") }}
          </p>
          <div>
            <ForwarderDropdown
              v-if="hasPermission(groupedItems[2].roles)"
              data-testid="test-ForwarderDropdown"
              class="mt-6"
            />
          </div>
          <div>
            <p class="text-primary font-weight-medium pt-2 pb-4">Services</p>
            <v-list
              v-for="service in groupedItems[2].services"
              :key="service.name"
              class="pa-1"
            >
              <v-list-item
                active-color="primary"
                active-class="active"
                class="d-flex list-item mx-3 rounded-lg cursor-pointer"
                :to="service.pagePath"
                :replace="true"
              >
                <template #prepend>
                  <div class="mr-2">
                    <v-icon :icon="getIcon(service)"></v-icon>
                  </div>
                </template>
                <v-list-item-title
                  class="font-weight-medium"
                  :class="serviceTitleClass(service.pagePath)"
                >
                  {{ t(service.name) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script setup lang="ts">
import { useNavigation } from "@/composables/useNavigation";
import ConrooLogo from "@/assets/main/conroo-logo.svg";
import ListItem from "@/layouts/navigation-bar/NavigationListItem.vue";
import TerminalDropdown from "@/layouts/navigation-bar/TerminalDropdown.vue";
import ForwarderDropdown from "@/layouts/navigation-bar/ForwarderDropdown.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import PAGE_NAMES from "@/internal-models/enums/page-names";

const {
  drawer,
  selectedItem,
  groupedItems,
  handleItemClick,
  hasPermission,
  logOut,
  goToSupport,
  isActiveRoute,
  getIcon,
} = useNavigation();

const router = useRouter();
const { t } = useI18n();

const serviceTitleClass = (pagePath: string) =>
  isActiveRoute(pagePath) ? "text-white" : "text-primary";
</script>

<style lang="css">
.list-item {
  background-color: rgb(var(--v-theme-greyish));
  padding: 0 0 0 1rem;

  &.active {
    background-color: rgb(var(--v-theme-primary));
  }
}
</style>
