<template>
  <v-container fluid class="pr-8">
    <v-row>
      <v-col cols="6">
        <TheMainHeader
          class="text-left py-15 pt-10"
          :text="$t('home')"
          data-testid="test-home"
        />
      </v-col>
    </v-row>
    <div>
      <v-row class="pa-5 h-25">
        <v-col sm="6" md="6" lg="6" xl="4">
          <ThePageCard
            :usable="hasPermissions(pageCards.terminalOperator.roles)"
            :item="pageCards.terminalOperator"
            data-testid="test-terminalOperator-card"
          />
        </v-col>
        <v-col
          v-if="hasPermissions(pageCards.conrooUser.roles)"
          sm="6"
          md="6"
          lg="6"
          xl="4"
        >
          <ThePageCard
            :usable="true"
            :item="pageCards.conrooUser"
            data-testid="test-conrooUser-card"
          />
        </v-col>
        <v-col sm="6" md="6" lg="6" xl="4">
          <ThePageCard
            :usable="hasPermissions(pageCards.trainOperator.roles)"
            :item="pageCards.trainOperator"
            data-testid="test-trainOperator-card"
          />
        </v-col>
        <v-col sm="6" md="6" lg="6" xl="4">
          <ThePageCard
            :usable="hasPermissions(pageCards.forwarderOperator.roles)"
            :item="pageCards.forwarderOperator"
            data-testid="test-forwarderOperator-card"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAuthStore } from "@/store/useAuthStore";
import ThePageCard from "@/components/ThePageCard.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { WebappUserRolesEnum } from "@/services/client/generated/api";
import { hasPermissionBasedOnRole } from "@/utils/role-utils";
import getPageCards from "@/constants/homepage-page-cards";

const pageCards = getPageCards();

const authStore = useAuthStore();
const userRoles = computed(() => authStore.userData?.roles || []);

const hasPermissions = (requiredRoles: WebappUserRolesEnum[] = []) => {
  return hasPermissionBasedOnRole(requiredRoles, userRoles.value);
};
</script>

<style lang="scss" scoped>
.router-link {
  text-decoration: none;
  color: inherit;
}
</style>
