import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import baseRoutes from "./base.routes";
import guestRoutes from "./guest.routes";

export const routes = [...baseRoutes, ...guestRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: RouteLocationNormalized, _from, next) => {
  const title = (to.meta as { title?: string }).title ?? "CONROO TERMINAL APP";
  document.title = title;

  const authStore = useAuthStore();
  authStore.getUser();

  const authenticated = authStore.authUser != null;
  if (!authenticated && to.path !== "/login") {
    next({ path: "/login" });
  } else if (["/", "/login"].includes(to.path) && authenticated) {
    next({ path: "/homepage" });
  } else {
    next();
  }
});

export default router;
