import { usePagination } from "@/observables/pagination";
import { ispsAdminUserClient } from "@/services/client/configs/services";
import {
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
  IspsTruckerTO,
  TruckerIdentificationTODocumentStatusEnum,
  TruckerIdentificationTOTerrorStatusEnum,
} from "@/services/client/generated";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useAuthStore } from "@/store/useAuthStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { onMounted, onUnmounted, reactive, ref } from "vue";

export function useUserManagementLogic() {
  const authStore = useAuthStore();

  const users = reactive<{ data: IspsTruckerTO[] }>({ data: [] });

  const admin = authStore.getUser()?.userData;
  const adminId = admin?.id;
  const snackbarStore = useSnackbarStore();

  const filledData = ref<boolean>(false);

  let intervalId: number | NodeJS.Timeout | null = null;
  const POLLING_TIME_MILLISECONDS = 1000 * 180; // 3 minutes

  let searchPayload: {
    userId: string | undefined;
    warningState: IspsTruckerChangeTODocumentStatusEnum | undefined;
    terrorState: IspsTruckerChangeTOTerrorStatusEnum | undefined;
  } = reactive({
    userId: undefined,
    warningState: undefined,
    terrorState: undefined,
  });

  const fetchTruckers = async (pageSize: number, pageIndex: number) => {
    if (adminId == null) {
      return false;
    }
    const currentPageUsers = await ispsAdminUserClient.getIspsTruckers(
      adminId,
      searchPayload.warningState,
      searchPayload.terrorState,
      searchPayload.userId,
      pageIndex,
      pageSize,
    );

    users.data.push(...currentPageUsers.data);
    if (currentPageUsers.data.length < pageSize) {
      return true;
    }

    return false;
  };

  const { pageSize, pageIndex, loading, restartPageIndex, incrementPageIndex } =
    usePagination(fetchTruckers);

  const filterUsers = async (payload: {
    userId: string | undefined;
    terrorState: TruckerIdentificationTOTerrorStatusEnum | undefined;
    warningState: TruckerIdentificationTODocumentStatusEnum | undefined;
  }) => {
    if (adminId == null) {
      return;
    }
    const { userId, terrorState, warningState } = payload;
    searchPayload = payload;

    restartPageIndex();

    const searchedUsers = await ispsAdminUserClient.getIspsTruckers(
      adminId,
      warningState,
      terrorState,
      userId?.trim(),
      pageIndex.value,
      pageSize,
    );

    incrementPageIndex();
    users.data = searchedUsers.data;
  };

  const addUserRevision = async (payload: {
    userId: string;
    data: IspsTruckerChangeTO;
  }) => {
    if (!adminId) {
      return;
    }
    await ispsAdminUserClient.ispsTruckerLog(
      adminId,
      payload.userId,
      payload.data,
    );

    filterUsers(searchPayload);
  };

  const resetIDNow = async (payload: string) => {
    if (adminId == null) {
      return;
    }
    try {
      await ispsAdminUserClient.deleteTruckerIdentifications(adminId, payload);
      snackbarStore.showSnackbar({
        text: "IDnow verification attempts succesfully resetted",
        snackbarType: SnackbarType.SUCCESS,
      });
    } catch (e) {
      snackbarStore.showSnackbar({
        text: "Error resetting IDnow tries for user",
        snackbarType: SnackbarType.ERROR,
      });
      console.error(e);
    }
  };

  const approveUser = async (payload: {
    userId: string;
    entryId: number;
    approve: boolean;
  }) => {
    const { userId, entryId, approve } = payload;
    //TODO: ISSUE WITH 204 - 200 fix in backend
    try {
      if (adminId == null) {
        return;
      }
      await ispsAdminUserClient.patchIspsTrucker(adminId, userId, entryId, {
        approved: approve,
      });
    } catch (e) {
      throw e;
    }

    filterUsers(searchPayload);
  };

  onMounted(async () => {
    await fetchTruckers(pageSize, pageIndex.value);
    incrementPageIndex();
    filledData.value = true;

    intervalId = setInterval(async () => {
      await filterUsers(searchPayload);
    }, POLLING_TIME_MILLISECONDS);
  });

  onUnmounted(() => {
    if (intervalId != null) {
      clearInterval(intervalId);
    }
  });

  return {
    authStore,
    adminId,
    users,
    filledData,
    loading,
    approveUser,
    filterUsers,
    addUserRevision,
    resetIDNow,
  };
}
