<template>
  <v-container>
    <TheMainHeader class="text-left" :text="$t('acceptanceRestrictions')" />
    <v-tabs align-tabs="center" grow v-model="tab" bg-color="white">
      <v-tab class="text-black" href="#acceptance">
        <div class="tab-title">{{ $t("createAcceptance") }}</div>
      </v-tab>
      <v-tab class="text-black" href="#oneToOne">
        <div class="tab-title" data-testid="test-onetoOneRestriction">
          {{ $t("create_1_1_acceptance") }}
        </div>
      </v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item :key="1" value="acceptance">
        <TerminalOperatorAcceptanceDeadline />
      </v-window-item>

      <v-window-item :key="2" value="oneToOne">
        <v-card flat>
          <TerminalOperatorOneToOneRestriction />
        </v-card>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TerminalOperatorAcceptanceDeadline from "@/views/terminal-operator/acceptance-deadline/TerminalOperatorAcceptanceDeadline.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import TerminalOperatorOneToOneRestriction from "@/views/terminal-operator/one-to-one-restriction/TerminalOperatorOneToOneRestriction.vue";

const tab = ref("acceptance");
</script>

<style lang="scss" scoped>
.tab-title {
  font-weight: bold;
  color: #00295b;

  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1.5rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 2rem;
  }
}
</style>
